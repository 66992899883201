
import './App.css';

// core version + navigation, pagination modules:
import Swiper from 'swiper';
import { Navigation, Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { FaBeer } from "react-icons/fa";


import logo from './img/Zatelly.png';
import hero from './img/hero-img.png';

import client1 from './img/client-1.png';
import client2 from './img/client-2.png';
import client3 from './img/client-3.png';
import client4 from './img/client-4.png';
import client5 from './img/client-5.png';
import client6 from './img/client-6.png';

import countsImg from './img/counts-img.svg';

import testimonial1 from './img/testimonials/testimonials-1.jpg';
import testimonial2 from './img/testimonials/testimonials-2.jpg';
import testimonial3 from './img/testimonials/testimonials-3.jpg';

function App() {
  // init Swiper:
const swiper = new Swiper('.swiper', {
  // configure Swiper to use modules
  modules: [Navigation, Pagination],
});

 
  return (
  <div className="App">
     
  <header id="header" className="fixed-top d-flex align-items-center">
    <div class="container d-flex align-items-center justify-content-between">

      <div class="logo">
                
         <a href="index.html"><img src={logo} alt="" class="img-fluid"/></a>
      </div>

      <nav id="navbar" class="navbar">
        <ul>
          <li><a class="nav-link scrollto active" href="#hero">Home</a></li>
          <li><a class="nav-link scrollto" href="#about">About</a></li>
          <li><a class="nav-link scrollto" href="#services">Services</a></li>
              
          <li><a class="nav-link scrollto" href="#contact">Contact</a></li>
          <li><a class="getstarted scrollto" href="#about">Get Started</a></li>
        </ul>
        <i class="bi bi-list mobile-nav-toggle"></i>
      </nav>

    </div>
  </header>

  <section id="hero" class="d-flex align-items-center">

    <div class="container">
      <div class="row">
        <div class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center">
          <h1 data-aos="fade-up">Cloud Solutions, Security & Innovation Globally In Present & Future</h1>
          <h2 data-aos="fade-up" data-aos-delay="400">For transactions, applications, enterprises & more.</h2>
          <div data-aos="fade-up" data-aos-delay="800">
            <a href="#about" class="btn-get-started scrollto">Get Started</a>
          </div>
        </div>
        <div class="col-lg-6 order-1 order-lg-2 hero-img" data-aos="fade-left" data-aos-delay="200">
          <img src={hero} class="img-fluid animated" alt=""/>
        </div>
      </div>
    </div>

  </section>

  <main id="main">

  <section id="clients" class="clients clients">
      <div class="container">

        <div class="row">

          <div class="col-lg-2 col-md-4 col-6">
            <img src={client1} class="img-fluid" alt="" data-aos="zoom-in"/>
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src={client2}  class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="100"/>
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src={client3}  class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="200"/>
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src={client4}  class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="300"/>
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src={client5}  class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="400"/>
          </div>

          <div class="col-lg-2 col-md-4 col-6">
            <img src={client6}  class="img-fluid" alt="" data-aos="zoom-in" data-aos-delay="500"/>
          </div>

        </div>

      </div>
  </section>

  <section id="about" class="about">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>About Us</h2>
        </div>

        <div class="row content">
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="150">
            <p>
            Zaptele is transforming how organizations approach infrastructure and cloud services. Our software suite provides the automation, integrated workflows, and lifecycle management you need to thrive in the cloud era.
            </p>
            <ul>
              <li><i class="ri-check-double-line"></i>Manage access to secrets and protect sensitive data with identity-based security.</li>
              <li><i class="ri-check-double-line"></i> Standardize infrastructure automation and reduce cloud spend by up to 20%.</li>
            </ul>
          </div>
          <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-up" data-aos-delay="300">
            <p>
            We understand that success doesn't happen in isolation. That's why Zaptele products integrate with the technologies that power your business.
            </p>
            <a href="#" class="btn-learn-more">Learn More</a>
          </div>
        </div>

      </div>
  </section>


  <section id="services" class="services">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Services</h2>
          
        </div>

        <div class="row">
          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="100">
              <div class="icon"><i class="bi bi-cpu-fill"></i></div>
              <h4 class="title"><a href="#">Cloud Managed Services</a></h4>
              <p class="description">The cloud has become indispensable for enterprises seeking to accelerate their transformation, speed up their time-to-market, and bolster resilience against disruptions. But making the most of the cloud's benefits requires proper planning and implementation, and a deep understanding of the technological and regulatory complexities.
              Moving your IT operations away from on-premises to a cloud environment doesn't have to be a daunting task. Done well, it can reduce complexities, improve cost efficiencies, and free up your IT resources to focus on continued innovation to achieve strategic business outcomes.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="200">
              <div class="icon"><i class="bi bi-bar-chart-line-fill"></i></div>
              <h4 class="title"><a href="">Digital Consultancy</a></h4>
              <p class="description">In a digital era of mobile, modern and cloud-native applications, the importance of User Experience (UX) and User Interface (UI) design can no longer be ignored. Done well, it improves the user experience and touchpoints across the entire customer journey, deepens engagement, and builds customer loyalty.
              With our in-house team of digital consultants and UI designers, you can be sure that the solutions we deliver are all crafted with the most important audience in mind – your customers.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="300">
              <div class="icon"><i class="bi bi-fan"></i></div>
              <h4 class="title"><a href="">Digital Transformation</a></h4>
              <p class="description">Digital transformation has evolved beyond taking paper out of processes. The focus has shifted towards holistic modernization of customer touchpoints, operating models, and end-to-end processes to improve customer experience and operating efficiency. Digital transformation delivers speed, scalability, security, and the ability to pivot instantly to meet any challenge or opportunity. Technologies like mobile apps, machine learning (ML), robotic process automation, artificial intelligence (AI), ChatBots, and multi-channel self-service are revolutionizing what it means to deliver a truly digital experience.</p>
            </div>
          </div>

          <div class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0">
            <div class="icon-box" data-aos="fade-up" data-aos-delay="400">
              <div class="icon"><i class="bi bi-escape"></i></div>
              <h4 class="title"><a href="">Modeling, Simulation & Training </a></h4>
              <p class="description">Our portfolio includes a wide range of modeling and simulation services; including desktop training, simulated environments for operationally relevant testing, digitally facilitated courseware, scenario-based wargaming simulations, and more. We offer a range of live-virtual-constructive capabilities for immersive environments in support of C5ISR, sensor technology evaluation and validation, Artificial Intelligence/Machine Learning (AI/ML), wargaming, and commercial applications. Our technology provides a closed, secure environment for testing exquisite customer capabilities in situations where the operating environment may be sensitive in nature. SMX also offers our customers digital twin capabilities to create a virtual replica of a physical object or system. These dynamic models can be outfitted with a variety of sensors to monitor performance and component health, predict behavior, and optimize system operation. Our comprehensive modeling and simulation technology is tailorable to meet customer needs and improves decision-making, reduces risk, and increases efficiency.</p>
            </div>
          </div>

        </div>

      </div>
  </section>

  <section id="features" class="features">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Features</h2>
          <p>A trusted partner with advanced technology in our DNA</p>
        </div>

        <div class="row" data-aos="fade-up" data-aos-delay="300">
          <div class="col-lg-4 col-md-4">
            <div class="icon-box">
              
              <i><FaBeer style={{ color: '#ffbb2c' }} /></i>
              <h3><a href="">Aligned for Impact</a></h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 mt-4 mt-md-0">
            <div class="icon-box">
              <i><FaBeer style={{ color: '#5578ff'}} /></i>
              <h3><a href="">A Platform for Transformation</a></h3>
            </div>
          </div>
          <div class="col-lg-4 col-md-4 mt-4 mt-md-0">
            <div class="icon-box">
              <i><FaBeer style={{ color: '#e80368' }} /></i>
              <h3><a href="">Driven by Outcomes</a></h3>
            </div>
          </div>
         
       
        </div>

      </div>
  </section>

  <section id="testimonials" class="testimonials section-bg">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Testimonials</h2>
          <p>Loved by great companies</p>
        </div>

        <div class="testimonials-slider swiper" data-aos="fade-up" data-aos-delay="100">
          <div class="swiper-wrapper">

            <div class="swiper-slide">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <img src={testimonial1} class="testimonial-img" alt=""/>
                  <h3>Saul Goodman</h3>
                  <h4>Ceo - MontyMobile</h4>
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    MontyMobile have been using Zaptele for the last year, and we are delighted to recommend it as one of the best testing services, given their continuous efforts to fulfil their promise of a professional and well-managed service. We appreciate their proactive and helpful support team who are always available to answer our inquiries and discuss our business needs.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <img src={testimonial2} class="testimonial-img" alt=""/>
                  <h3>Sara Wilsson</h3>
                  <h4>Operations Manager - NGN Group</h4>
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    We have been working with Zaptele since the very beginning, and we could not be happier with the decision of moving to their platform: Global coverage, detailed reports, easy to use and very intuitive interface, accurate results and an excellent support team. Thanks to Testelium, we can be sure we are offering our clients routes that will meet their quality standards.

                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>

            <div class="swiper-slide">
              <div class="testimonial-wrap">
                <div class="testimonial-item">
                  <img src={testimonial3} class="testimonial-img" alt=""/>
                  <h3>Jena Karlis</h3>
                  <h4>Routing Officer - NRS Group</h4>
                  <p>
                    <i class="bx bxs-quote-alt-left quote-icon-left"></i>
                    We have tried several similar solutions but Testelium is without a doubt the one that has given us the best results, the solution that has the most coverage, very good support with very good price plans, very easy to configure and use, highly recommended.
                    <i class="bx bxs-quote-alt-right quote-icon-right"></i>
                  </p>
                </div>
              </div>
            </div>


          </div>
          <div class="swiper-pagination"></div>
        </div>

      </div>
  </section>



  

  <section id="contact" class="contact">
      <div class="container">

        <div class="section-title" data-aos="fade-up">
          <h2>Contact Us</h2>
        </div>

        <div class="row">

          <div class="col-lg-4 col-md-6" data-aos="fade-up" data-aos-delay="100">
            <div class="contact-about">
              <h3>Zaptele</h3>
              
              <div class="social-links">
                <a href="#" class="twitter"><i class="bi bi-twitter"></i></a>
                <a href="#" class="facebook"><i class="bi bi-facebook"></i></a>
                <a href="#" class="instagram"><i class="bi bi-instagram"></i></a>
                <a href="#" class="linkedin"><i class="bi bi-linkedin"></i></a>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 mt-4 mt-md-0" data-aos="fade-up" data-aos-delay="200">
            <div class="info">
              <div>
                <i class="ri-map-pin-line"></i>
                <p>A108 Adam Street</p>
                <p>New York, NY 535022</p>
              </div>

              <div>
                <i class="ri-mail-send-line"></i>
                <p>hello@zaptele.online</p>
              </div>

              <div>
                <i class="ri-phone-line"></i>
                <p>+1 5589 55488 555</p>
              </div>

            </div>
          </div>

          <div class="col-lg-5 col-md-12" data-aos="fade-up" data-aos-delay="300">
            <form action="forms/contact.php" method="post" role="form" class="php-email-form">
              <div class="form-group">
                <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required/>
              </div>
              <div class="form-group">
                <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required/>
              </div>
              <div class="form-group">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required/>
              </div>
              <div class="form-group">
                <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
              </div>
              <div class="my-3">
                <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
              </div>
              <div class="text-center"><button type="submit">Send Message</button></div>
            </form>
          </div>

        </div>

      </div>
  </section>
</main>
<footer id="footer">
    <div class="container">
      <div class="row d-flex align-items-center">
        <div class="col-lg-6 text-lg-left text-center">
          <div class="copyright">
            &copy; Copyright <strong>Zaptele</strong>. All Rights Reserved
          </div>
          
        </div>
        <div class="col-lg-6">
          <nav class="footer-links text-lg-right text-center pt-2 pt-lg-0">
            <a href="#intro" class="scrollto">Home</a>
            <a href="#about" class="scrollto">About</a>
            <a href="#">Privacy Policy</a>
            <a href="#">Terms of Use</a>
          </nav>
        </div>
      </div>
    </div>
</footer>

  </div>
  );
}

export default App;
